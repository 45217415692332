export const environment = {
  production: false,
  backend: {
    baseUrl: "https://be-dev.siram.elvoon.it/approva-ws/api/v1",
  },
  oauth2_redirect_url: "https://be-dev.siram.elvoon.it/oauth2/redirect", 
  google: { 
    auth_url:
    "https://be-dev.siram.elvoon.it/approva-ws/api/v1/oauth2/authorize/google?redirect_uri=https://approva-dev.siram.elvoon.it/authentication/oauth2/redirect", // dev siram
    clientId:
      "622187157441-1ad8mu0gv3bsr5ualt8glc24ltqhf6q8.apps.googleusercontent.com",
    apikeydrive:'AIzaSyC9hbqh6rtoWmVtaXeUMBK0CNVVZnZctgw',
    appId:'622187157441',
    mapkey:'AIzaSyD5x5lo2OTmK_mCG2Mwom-9viEkSSiR7PQ'
  },
  deleghe: "claudia.vissicchio@demo.noovle.it"
};  
